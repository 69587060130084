import { NoteEntityId } from "@sp-crm/core";
import React from "react";
import { Header } from "../header";
import { ModernNotes } from "./modern-notes";

interface NotesProps {
    entityId: NoteEntityId;
}

export const Notes: React.FC<NotesProps> = props => {
    const { entityId } = props;
    return (
        <div className="input-form-block overflow-y-scroll max-h-128">
            <Header iconName="09_Notes">Interaction Notes</Header>
            <ModernNotes entityId={entityId} />
        </div>
    );
};
