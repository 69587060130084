import {
    CALENDAR_DATE_NO_YEAR_PLACEHOLDER,
    CalendarDate,
    findRelatedField,
    getEntityField,
    getQuestionFieldFromSelect,
    IBridgeFieldMetadata,
    IBridgeSelect,
    IQuestion,
    Question,
    QUESTION_FIELD_IDENTIFIER,
    QuestionCategories,
    questionMetadata,
    QuestionType,
    RegionId,
    TenantRegion,
    UserId,
} from "@sp-crm/core";
import {
    isRelationDescription,
    RelationDescription,
} from "components/advanced-search/types";
import {
    AdvancedSearchConditionNodeType,
    AdvancedSearchEntityType,
    AdvancedSearchRequest,
    AdvancedSearchSelectInput,
    BridgeEntityResult,
    BridgeRelationResult,
    FieldConditionOperator,
    GetCustomListsQuery,
} from "generated/graphql";
import { useCallback } from "react";
import { useQueryClient } from "react-query";

export const FIELD_PICKER_TYPE_SEPARATOR = "@";

export interface CustomReportsRouteParams {
    reportId?: string;
}

export const useRefreshCustomQueries = () => {
    const client = useQueryClient();
    return useCallback(() => {
        client.invalidateQueries(["getSavedQueries"]);
    }, [client]);
};

export function findPreferredFilterField(entity: BridgeEntityResult) {
    if (entity.preferredFilterField) {
        const found = getEntityField(entity, entity.preferredFilterField);
        if (found) {
            return found;
        }
    }

    return entity.fields[0];
}

export const getInitialOperatorAndValue = (
    questionType: QuestionType,
    regions: TenantRegion[],
    currentUserId: UserId,
    customLists: GetCustomListsQuery["getCustomLists"],
    fieldMetadata: IBridgeFieldMetadata | null,
): {
    operator: FieldConditionOperator;
    dateValue?: Date;
    calendarDateValue?: string;
    textValue?: string;
    numberValue?: number;
} => {
    switch (questionType) {
        case QuestionType.binary:
            return {
                operator: FieldConditionOperator.IsTrue,
            };
        case QuestionType.text:
            return {
                operator: FieldConditionOperator.StringContains,
                textValue: "",
            };
        case QuestionType.nary:
            return {
                operator: FieldConditionOperator.NaryContains,
                textValue:
                    fieldMetadata?.naryValues?.length > 0
                        ? fieldMetadata.naryValues[0].value
                        : "",
            };
        case QuestionType.date:
            return {
                operator: FieldConditionOperator.DateAfter,
                dateValue: CalendarDate.todayNoTime.dayStart(),
            };
        case QuestionType.calendarDateNoYear:
            return {
                operator: FieldConditionOperator.DateAfter,
                calendarDateValue: `${CALENDAR_DATE_NO_YEAR_PLACEHOLDER}-01-01`,
            };
        case QuestionType.number:
            return {
                operator: FieldConditionOperator.NumberEquals,
                numberValue: 0,
            };
        case QuestionType.currency:
            return {
                operator: FieldConditionOperator.NumberEquals,
                numberValue: 0,
            };
        case QuestionType.range:
            return {
                operator: FieldConditionOperator.RangeContains,
                numberValue: 0,
            };
        case QuestionType.region:
            return {
                operator: FieldConditionOperator.StringEquals,
                textValue: regions[0].id,
            };
        case QuestionType.user:
            return {
                operator: FieldConditionOperator.IdEquals,
                textValue: currentUserId,
            };
        case QuestionType.customList: {
            const matchingCustomList = customLists?.find(
                c => c.key === fieldMetadata?.customListKey,
            );
            return matchingCustomList?.items?.length > 0
                ? {
                      operator: FieldConditionOperator.CustomListEquals,
                      textValue: matchingCustomList.items[0].id,
                  }
                : {
                      operator: FieldConditionOperator.IsSet,
                  };
        }
        default:
            return { operator: FieldConditionOperator.StringEquals, textValue: "" };
    }
};

export const makeInitialAdvancedSearchRequest = (
    entities: BridgeEntityResult[],
    entityType: AdvancedSearchEntityType,
    regionId: RegionId,
    hasMultipleRegions: boolean,
    regions: TenantRegion[],
    currentUserId: UserId,
    customLists: GetCustomListsQuery["getCustomLists"],
): AdvancedSearchRequest => {
    const entityMetadata = entities.find(e => e.name === entityType);
    if (!entityMetadata) {
        throw new Error(`Unknown entity type: ${entityType}`);
    }

    const preferredField = findPreferredFilterField(entityMetadata);
    const select = entityMetadata.defaultSelect || [];

    const initialFieldConditon = {
        nodeType: AdvancedSearchConditionNodeType.FieldCondition,
        fieldName: preferredField.name,
        ...getInitialOperatorAndValue(
            preferredField.questionType,
            regions,
            currentUserId,
            customLists,
            preferredField,
        ),
    };

    switch (entityType) {
        case AdvancedSearchEntityType.Client: {
            const clientRequest: AdvancedSearchRequest = {
                entityType: AdvancedSearchEntityType.Client,
                sort: {
                    column: "dateAdded",
                    ascending: false,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };
            if (hasMultipleRegions) {
                clientRequest.condition.children.push({
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                });
            }
            return clientRequest;
        }
        case AdvancedSearchEntityType.Community: {
            const communityRequest: AdvancedSearchRequest = {
                entityType: AdvancedSearchEntityType.Community,
                sort: {
                    column: "name",
                    ascending: true,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };
            if (hasMultipleRegions) {
                communityRequest.condition.children.push({
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                });
            }
            return communityRequest;
        }
        case AdvancedSearchEntityType.ReferenceBusiness:
        case AdvancedSearchEntityType.ReferenceContact: {
            const referenceRequest: AdvancedSearchRequest = {
                entityType,
                sort: {
                    column: "dateAdded",
                    ascending: false,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };
            if (hasMultipleRegions) {
                referenceRequest.condition.children.push({
                    nodeType: AdvancedSearchConditionNodeType.FieldCondition,
                    operator: FieldConditionOperator.StringEquals,
                    fieldName: "regionId",
                    textValue: regionId,
                });
            }
            return referenceRequest;
        }
        case AdvancedSearchEntityType.CommunityContact: {
            const communityContactRequest = {
                entityType,
                sort: {
                    column: "community.name",
                    ascending: true,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };

            return communityContactRequest;
        }
        case AdvancedSearchEntityType.Activity: {
            const activityLogRequest = {
                entityType,
                sort: {
                    column: "createdAt",
                    ascending: false,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };

            return activityLogRequest;
        }
        case AdvancedSearchEntityType.Invoice: {
            const invoiceRequest = {
                entityType,
                sort: {
                    column: "sentDate",
                    ascending: false,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };

            return invoiceRequest;
        }
        case AdvancedSearchEntityType.Task: {
            const taskRequest = {
                entityType,
                sort: {
                    column: "createdAt",
                    ascending: false,
                },
                condition: {
                    nodeType: AdvancedSearchConditionNodeType.And,
                    children: [
                        {
                            nodeType: AdvancedSearchConditionNodeType.Or,
                            children: [initialFieldConditon],
                        },
                    ],
                },
                select,
            };

            return taskRequest;
        }
        default: {
            const exhaustiveCheck: never = entityType;
            throw new Error(`Unhandled entity type: ${exhaustiveCheck}`);
        }
    }
};

export const getQuestionsForEntityType = (
    entityType: string,
    entities: BridgeEntityResult[],
): IQuestion[] => {
    return (entities.find(e => e.name === entityType)?.questions || [])
        .map(q => Question.load(q))
        .sort((a, b) => a.title.localeCompare(b.title));
};

const fieldSegmentsToColumnTitle = (
    field: string[],
    entityMetadata: BridgeEntityResult,
    entities: BridgeEntityResult[],
): string => {
    if (field.length === 1) {
        const fieldMetadata = getEntityField(entityMetadata, field[0]);
        return fieldMetadata?.title || field[0];
    } else {
        const relation = entityMetadata.relations.find(r => r.name === field[0]);
        const relationEntityMetadata = entities.find(
            e => e.name === relation?.otherEntityName,
        );
        if (!relationEntityMetadata) return ``;
        return `${relation?.title || field[0]}: ${fieldSegmentsToColumnTitle(
            field.slice(1),
            relationEntityMetadata,
            entities,
        )}`;
    }
};

export const getColumnTitle = (
    column: AdvancedSearchSelectInput,
    entityType: AdvancedSearchEntityType,
    entities: BridgeEntityResult[],
) => {
    if (column.title) {
        return column.title;
    }

    const entityMetadata = entities.find(e => e.name === entityType);

    if (entityMetadata) {
        const questionField = getQuestionFieldFromSelect(
            column,
            entityMetadata,
            entities,
        );
        if (questionField) {
            const questions = getQuestionsForEntityType(
                questionField.entityMetadata.name,
                entities,
            );

            const matchingQuestion = questions.find(
                q => q.id === questionField.questionId,
            );

            if (matchingQuestion?.title) {
                return `${questionField.titlePrefix}${matchingQuestion.title}`;
            }
        } else if (column.fieldName) {
            const priorityField = entityMetadata.priorityFields?.find(
                f => f.fieldName === column.fieldName,
            );

            if (priorityField?.title) {
                return priorityField.title;
            }

            return fieldSegmentsToColumnTitle(
                column.fieldName.split("."),
                entityMetadata,
                entities,
            );
        }
    }

    return "_Unknown_";
};

export interface FieldPickerField {
    key: string;
    value: string;
    title: string;
    categoryLabel: string;
    relation?: string;
    order?: number;
}

const getFieldsFromRelation = (
    relation: BridgeRelationResult,
    bridgeTypes: BridgeEntityResult[],
    alwaysInclude: IBridgeSelect | null,
    exclude: (IBridgeSelect | RelationDescription)[],
    includeQuestions: boolean,
    fieldPrefix: string = "",
    titlePrefix: string = "",
): FieldPickerField[] => {
    if (
        !relation.selectable ||
        exclude?.some(
            e =>
                isRelationDescription(e) &&
                relation.name &&
                relation.name.startsWith(e.prefix),
        )
    ) {
        return [];
    }
    const relationFields: FieldPickerField[] = [];
    const relatedEntity = bridgeTypes.find(e => e.name === relation.otherEntityName);
    relatedEntity.fields
        .filter(f => f.reportBehavior.selectable)
        .forEach(field => {
            const fieldName = [fieldPrefix, relation.name, field.name]
                .filter(x => !!x)
                .join(".");
            const categoryLabel = [titlePrefix, relation.title]
                .filter(x => !!x)
                .join(": ");

            const isRelationFieldAdded = exclude?.some(
                e => !isRelationDescription(e) && e.fieldName === fieldName,
            );
            if (!isRelationFieldAdded || alwaysInclude?.fieldName === fieldName) {
                relationFields.push({
                    key: fieldName,
                    value: `field${FIELD_PICKER_TYPE_SEPARATOR}${fieldName}`,
                    title: field.title,
                    categoryLabel,
                    relation: relation.name,
                });
            }
        });

    const relationQuestions = includeQuestions
        ? getQuestionsForEntityType(relatedEntity.name, bridgeTypes)
        : [];
    relationQuestions.forEach(question => {
        const fieldName = [
            fieldPrefix,
            relation.name,
            `${QUESTION_FIELD_IDENTIFIER}:${question.id}`,
        ]
            .filter(x => !!x)
            .join(".");

        const categoryLabel = [titlePrefix, relation.title].filter(x => !!x).join(": ");

        const isRelationFieldAdded = exclude?.some(
            e => !isRelationDescription(e) && e.fieldName === fieldName,
        );
        if (!isRelationFieldAdded || alwaysInclude?.fieldName === fieldName) {
            relationFields.push({
                key: fieldName,
                value: `field${FIELD_PICKER_TYPE_SEPARATOR}${fieldName}`,
                title: question.title,
                categoryLabel,
                relation: relation.name,
            });
        }
    });

    relatedEntity.relations.forEach(secondOrderRelation => {
        relationFields.push(
            ...getFieldsFromRelation(
                secondOrderRelation,
                bridgeTypes,
                alwaysInclude,
                exclude,
                includeQuestions,
                [fieldPrefix, relation.name].filter(x => !!x).join("."),
                [titlePrefix, relation.title].filter(x => !!x).join(": "),
            ),
        );
    });

    return relationFields;
};

export const getNormalizedFields = (
    entityMetadata: BridgeEntityResult,
    bridgeTypes: BridgeEntityResult[],
    alwaysInclude: IBridgeSelect | null,
    exclude: (IBridgeSelect | RelationDescription)[],
    searchText: string,
    includeQuestions: boolean,
): [Map<string, FieldPickerField[]>, FieldPickerField[]] => {
    let normalizedFields: FieldPickerField[] = [];
    const standardFields = entityMetadata.fields;

    const relationFields: FieldPickerField[] = [];
    entityMetadata.relations.forEach(relation => {
        relationFields.push(
            ...getFieldsFromRelation(
                relation,
                bridgeTypes,
                alwaysInclude,
                exclude,
                includeQuestions,
            ),
        );
    });

    const entityQuestions = includeQuestions
        ? getQuestionsForEntityType(entityMetadata.name, bridgeTypes)
        : [];

    const priorityFields: FieldPickerField[] = (entityMetadata.priorityFields || [])
        .filter(f => findRelatedField(f.fieldName, entityMetadata, bridgeTypes))
        .map((f, index) => {
            return {
                key: f.fieldName,
                value: `field${FIELD_PICKER_TYPE_SEPARATOR}${f.fieldName}`,
                title: getColumnTitle(
                    f,
                    entityMetadata.name as AdvancedSearchEntityType,
                    bridgeTypes,
                ),
                categoryLabel: "Frequently Used",
                order: index,
            };
        });

    normalizedFields = priorityFields
        .concat(
            standardFields
                .filter(
                    f =>
                        f.reportBehavior.selectable &&
                        (f.name === alwaysInclude?.fieldName ||
                            !exclude?.some(
                                e => !isRelationDescription(e) && e.fieldName === f.name,
                            )),
                )
                .map(f => ({
                    key: f.name as string,
                    value: `field${FIELD_PICKER_TYPE_SEPARATOR}${f.name}`,
                    title: f.title,
                    categoryLabel: "Standard Fields",
                })),
        )
        .concat(relationFields)
        .concat(
            entityQuestions
                .filter(
                    q =>
                        q.id === alwaysInclude?.questionId ||
                        !exclude?.some(
                            e => !isRelationDescription(e) && e.questionId === q.id,
                        ),
                )
                .map(q => ({
                    key: q.id as string,
                    value: `questionId${FIELD_PICKER_TYPE_SEPARATOR}${q.id}`,
                    title: q.title,
                    categoryLabel:
                        entityMetadata.name === "Client"
                            ? questionMetadata[q.category as QuestionCategories]
                                  .clientHeading
                            : entityMetadata.name === "Community"
                            ? questionMetadata[q.category as QuestionCategories]
                                  .communityHeading
                            : "Custom Fields",
                }))
                .sort((a, b) => {
                    if (a.categoryLabel === b.categoryLabel) {
                        return a.title
                            .toLocaleLowerCase()
                            .localeCompare(b.title.toLocaleLowerCase());
                    }

                    return a.categoryLabel
                        .toLocaleLowerCase()
                        .localeCompare(b.categoryLabel.toLocaleLowerCase());
                }),
        )
        .filter(
            f =>
                !searchText.trim() ||
                f.title
                    .toLocaleLowerCase()
                    .includes(searchText.trim().toLocaleLowerCase()) ||
                f.categoryLabel
                    .toLocaleLowerCase()
                    .includes(searchText.trim().toLocaleLowerCase()),
        );

    return [
        normalizedFields.reduce((acc, curr) => {
            const fields = acc.get(curr.categoryLabel) || [];
            fields.push(curr);
            acc.set(curr.categoryLabel, fields);

            return acc;
        }, new Map<string, FieldPickerField[]>()),
        normalizedFields,
    ];
};
