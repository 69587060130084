import { PhoneArrowUpRightIcon } from "@heroicons/react/20/solid";
import { formatPhoneNumber } from "@sp-crm/core";
import { Spinner } from "components/ui/spinner";
import * as React from "react";
import { useResponsiveMode } from "store/selectors/hooks";
import { phoneUri } from "util/uri";
import { ResponsiveMode } from "../../store/reducers/responsive";

interface PhoneInputOwnProps {
    label?: string;
    initial?: string | null;
    onCommit?: (newValue: string) => void;
    placeholder?: string;
    disabled?: boolean;
    faxNumber?: boolean;
}

type Action =
    | { type: "change"; value: string }
    | { type: "blur"; value: string }
    | { type: "updateUpstream"; value: string };

interface ComponentState {
    upstreamValue: string;
    mode: "pristine" | "dirty" | "pending";
    currentValue: string;
}

const invalidAction = (action: Action): never => {
    throw new Error(`unknown action type ${action.type}`);
};
const invalidMode = (mode: ComponentState["mode"]): never => {
    throw new Error(`unknown mode ${mode}`);
};

const reducer: React.Reducer<ComponentState, Action> = (p, a): ComponentState => {
    switch (a.type) {
        case "change":
            return { ...p, mode: "dirty", currentValue: a.value };
        case "blur":
            return {
                ...p,
                mode: p.upstreamValue === a.value ? "pristine" : "pending",
                currentValue: a.value,
            };
        case "updateUpstream":
            switch (p.mode) {
                case "dirty":
                    return { ...p, upstreamValue: a.value };
                case "pending":
                    return {
                        ...p,
                        mode: p.currentValue === a.value ? "pristine" : "pending",
                        upstreamValue: a.value,
                    };
                case "pristine":
                    return { ...p, currentValue: a.value, upstreamValue: a.value };
            }
            return invalidMode(p.mode);
    }
    return invalidAction(a);
};

export const PhoneInput: React.FC<PhoneInputOwnProps> = props => {
    const { label, disabled, placeholder, faxNumber, onCommit } = props;
    const initial = props.initial || "";
    const [state, dispatch] = React.useReducer(reducer, {
        upstreamValue: initial,
        currentValue: initial,
        mode: initial === formatPhoneNumber(initial) ? "pristine" : "dirty",
    });

    const inputValue = formatPhoneNumber(state.currentValue);

    const handleBlur = React.useCallback(() => {
        if (state.mode === "dirty" && onCommit) {
            dispatch({ type: "blur", value: inputValue });
            onCommit(inputValue);
        }
    }, [dispatch, inputValue, state.mode, onCommit]);

    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: "change", value: e.target.value });
        },
        [dispatch],
    );

    React.useEffect(() => {
        dispatch({ type: "updateUpstream", value: initial });
    }, [dispatch, initial]);

    return (
        <div className="w-full">
            <label>
                {label ? <div className="mb-1">{label}</div> : null}
                <div className="relative">
                    <span className="box flex-1">
                        <input
                            value={inputValue}
                            disabled={!!disabled}
                            placeholder={placeholder}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="tel"
                            autoComplete="off"
                            className="form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        />
                    </span>
                    {state.mode === "pending" ? (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <Spinner />
                        </div>
                    ) : null}
                </div>
            </label>
            {faxNumber ? null : <PhoneCallButton value={inputValue} />}
        </div>
    );
};

interface PhoneCallButtonProps {
    value: string;
}

const PhoneCallButton: React.FC<PhoneCallButtonProps> = props => {
    const { value } = props;
    const responsiveMode = useResponsiveMode();

    if (responsiveMode >= ResponsiveMode.large) {
        return null;
    }

    const url = phoneUri(value);
    const titleDescription = "Call " + value;
    if (!url || url.length === 0) return null;
    return (
        <div className="url-text-button">
            <a
                rel="noopener noreferrer"
                href={url}
                target="_blank"
                title={titleDescription}>
                <PhoneArrowUpRightIcon className="w-4 h-4" />
            </a>
        </div>
    );
};
