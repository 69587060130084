import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { IEditor } from "roosterjs";

interface TextSizePickerPanelProps {
    editor: IEditor;
    current: (editor: IEditor) => string;
    onTextSizeSelected: (size: string) => void;
}

const fontSizes = [
    { size: "9.75pt", label: "Small" },
    { size: "12pt", label: "Normal", default: true },
    { size: "14pt", label: "Large" },
    { size: "18pt", label: "Huge" },
];

export const TextSizePickerPanel: React.FC<TextSizePickerPanelProps> = props => {
    const { editor, current, onTextSizeSelected } = props;

    const [currentSize, setCurrentSize] = React.useState(
        current(editor) || fontSizes.find(x => x.default)?.size,
    );

    return (
        <ul className="py-2">
            {fontSizes.map(size => (
                <li key={size.size}>
                    <button
                        onClick={e => {
                            e.preventDefault();
                            setCurrentSize(size.size);
                            onTextSizeSelected(size.size);
                        }}>
                        <div className="flex items-center pr-4 space-x-1">
                            <div className="pl-1">
                                {currentSize === size.size ? (
                                    <CheckIcon className="w-4 h-4" />
                                ) : (
                                    <div className="w-4 h-4">&nbsp;</div>
                                )}
                            </div>
                            <span style={{ fontSize: size.size }}>{size.label}</span>
                        </div>
                    </button>
                </li>
            ))}
        </ul>
    );
};
