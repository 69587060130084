import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactDOM from "react-dom/client";
import { Input } from "./input";
import { PrimaryButton } from "./primary-button";

interface Props {
    title: string;
    prompt: string;
    resolve: (value: string) => void;
}

const PromptDialog = (props: Props) => {
    const { prompt, resolve, title } = props;
    const [isHidden, setIsHidden] = React.useState(false);
    const [result, setResult] = React.useState("");
    const complete = React.useCallback(() => {
        resolve(result);
        setIsHidden(true);
    }, [result, resolve]);

    return (
        <Transition show={!isHidden} appear={true}>
            <Dialog as="div" className="relative z-1500" onClose={close}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed z-1500 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <form onSubmit={complete}>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <QuestionMarkCircleIcon
                                                className="h-6 w-6 text-brand-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                                            <DialogTitle
                                                as="h3"
                                                className="text-lg leading-6 font-medium text-gray-900">
                                                {title}
                                            </DialogTitle>
                                            <div className="mt-2">
                                                <Input
                                                    value={result}
                                                    onChange={e =>
                                                        setResult(e.target.value)
                                                    }
                                                    label={prompt}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <div />
                                        <PrimaryButton>Ok</PrimaryButton>
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export const fancyPrompt = async (title: string, prompt: string): Promise<string> => {
    const wrapper = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(wrapper);
    const promise = new Promise<string>(resolve => {
        root.render(<PromptDialog title={title} resolve={resolve} prompt={prompt} />);
    });
    const result = await promise;
    root.unmount();
    setTimeout(() => wrapper.remove());
    return result;
};
