import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { IBridgeSelect } from "@sp-crm/core";
import { MultiEntityFieldPicker } from "components/advanced-search/multi-entity-field-picker";
import { fancyPrompt } from "components/ui/fancy-prompt";
import { secondaryClasses } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { AdvancedSearchEntityType } from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { IEditor } from "roosterjs";
import { InsertPlaceholderIcon, TriangleDownIcon } from "./ribbon-icons";

interface PlaceholderPopoverProps {
    placeholderTypes?: AdvancedSearchEntityType[];
    editor: IEditor;
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
}

interface PlaceholderPopoverState {
    lastPlaceholderEntityType: AdvancedSearchEntityType | null;
    waitingForTokenReplacement: string | null;
    closePlaceholderPopover: (() => void) | null;
}

const initialState: PlaceholderPopoverState = {
    lastPlaceholderEntityType: null,
    waitingForTokenReplacement: null,
    closePlaceholderPopover: null,
};

type PlaceholderPopoverAction =
    | {
          type: "placeholderSelected";
          entityType: AdvancedSearchEntityType;
      }
    | {
          type: "tokenInserted";
      }
    | {
          type: "waitForToken";
          tokenValue: string;
          closePopover: () => void;
      };

const reducer: React.Reducer<PlaceholderPopoverState, PlaceholderPopoverAction> = (
    state,
    action,
): PlaceholderPopoverState => {
    return produce(state, draft => {
        switch (action.type) {
            case "placeholderSelected":
                draft.lastPlaceholderEntityType = action.entityType;
                break;
            case "tokenInserted":
                draft.waitingForTokenReplacement = null;
                draft.closePlaceholderPopover = null;
                break;
            case "waitForToken":
                draft.waitingForTokenReplacement = action.tokenValue;
                draft.closePlaceholderPopover = action.closePopover;
                break;
        }
    });
};

export const PlaceholderPopover: React.FC<PlaceholderPopoverProps> = props => {
    const { placeholderTypes, editor, tokenReplacements, onTokenInserted } = props;

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const insertToken = React.useCallback(
        (text: string, closePopover: () => void) => {
            editor.insertContent(text);
            closePopover && closePopover();
            dispatch({ type: "tokenInserted" });
            editor.focus();
        },
        [editor, dispatch],
    );

    const handlePlaceholder = React.useCallback(
        async (
            entityType: AdvancedSearchEntityType,
            placeholder: IBridgeSelect,
            closePopover: () => void,
        ) => {
            if (placeholder && editor) {
                dispatch({ type: "placeholderSelected", entityType });

                const tokenValue = `${entityType.toLowerCase()}.${placeholder.fieldName}`;

                if (onTokenInserted) {
                    onTokenInserted(tokenValue);
                }

                if (tokenReplacements) {
                    if (typeof tokenReplacements[tokenValue] === "string") {
                        insertToken(tokenReplacements[tokenValue], closePopover);
                    } else {
                        dispatch({ type: "waitForToken", tokenValue, closePopover });
                    }
                } else {
                    const fallback = await fancyPrompt(
                        "Fallback",
                        `If ${tokenValue} is not available, what should be used instead?`,
                    );
                    if (fallback && fallback.length > 0) {
                        insertToken(`{{${tokenValue} | ${fallback}}}`, closePopover);
                    } else {
                        insertToken(`{{${tokenValue}}}`, closePopover);
                    }
                }
            }
        },
        [editor, dispatch, tokenReplacements, onTokenInserted, insertToken],
    );

    React.useEffect(() => {
        if (
            state.waitingForTokenReplacement &&
            tokenReplacements &&
            editor &&
            typeof tokenReplacements[state.waitingForTokenReplacement] === "string"
        ) {
            insertToken(
                tokenReplacements[state.waitingForTokenReplacement],
                state.closePlaceholderPopover,
            );
        }
    }, [tokenReplacements]); // eslint-disable-line react-hooks/exhaustive-deps

    if (placeholderTypes?.length > 0) {
        return (
            <Popover as="div" className="relative inline-block text-left ml-4">
                {({ open }) => (
                    <>
                        <PopoverButton className={secondaryClasses}>
                            <div className="flex items-center space-x-2">
                                <InsertPlaceholderIcon className="w-5 h-5" />
                                <TriangleDownIcon className="w-1.5 h-1.5" />
                            </div>
                        </PopoverButton>
                        {open ? (
                            <PopoverPanel
                                static
                                className="origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                {({ close }) => (
                                    <>
                                        <MultiEntityFieldPicker
                                            initialType={state.lastPlaceholderEntityType}
                                            entityTypes={placeholderTypes}
                                            onSelected={(e, p) =>
                                                handlePlaceholder(e, p, close)
                                            }
                                        />
                                        {state.waitingForTokenReplacement ? (
                                            <div className="absolute inset-0 bg-gray-500 bg-opacity-25 flex justify-center items-center">
                                                <Spinner />
                                            </div>
                                        ) : null}
                                    </>
                                )}
                            </PopoverPanel>
                        ) : null}
                    </>
                )}
            </Popover>
        );
    }

    return null;
};
