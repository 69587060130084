import { EmailTemplateId, emailTokens, parseEntityIdSafe } from "@sp-crm/core";
import * as React from "react";
import { OutboundMessageSpecialCommunityComparison } from "../../../generated/graphql";
import { Template } from "../../editor/template";
import { Checkbox } from "../../ui/checkbox";
import { Select } from "../../ui/select";

export interface SpecialComposeCommmunityComparisonProps {
    values: OutboundMessageSpecialCommunityComparison;
    templates: Template[];
    liveLinkText: string;
    onChange: (values: OutboundMessageSpecialCommunityComparison) => void;
}

export const SpecialComposeCommmunityComparison: React.FC<
    SpecialComposeCommmunityComparisonProps
> = props => {
    const { values, onChange, templates, liveLinkText } = props;
    return (
        <div className="space-y-2">
            <Checkbox
                checked={values.includeLiveLink}
                label={`Insert link to live community comparison with label "${liveLinkText}"`}
                onChange={e => {
                    onChange({ ...values, includeLiveLink: e.target.checked });
                }}
            />
            {values.includeLiveLink ? (
                <em>
                    <span className="block">
                        You&apos;ll need to add &quot;
                        <code>{emailTokens.intakeLiveLink}</code>&quot; to the message
                        body so we know where to insert the community comparison link.
                    </span>
                </em>
            ) : null}
            <Checkbox
                checked={values.includePDF}
                label="Attach Community Comparison PDF"
                onChange={e => {
                    onChange({ ...values, includePDF: e.target.checked });
                }}
            />
            {values.includePDF ? (
                <div className="space-y-2 pl-2 border-l-8 border-gray-100">
                    <Checkbox
                        checked={values.showPrimaryContacts}
                        label="Include primary contacts"
                        onChange={e => {
                            onChange({
                                ...values,
                                showPrimaryContacts: e.target.checked,
                            });
                        }}
                    />
                    <Checkbox
                        checked={values.showFinancialDetails}
                        label="Include financial details"
                        onChange={e => {
                            onChange({
                                ...values,
                                showFinancialDetails: e.target.checked,
                            });
                        }}
                    />
                    <Checkbox
                        checked={values.showCommunityRecords}
                        label="Include community records/licensing info"
                        onChange={e => {
                            onChange({
                                ...values,
                                showCommunityRecords: e.target.checked,
                            });
                        }}
                    />
                    {templates.length > 0 ? (
                        <div className="space-y-2">
                            <Checkbox
                                checked={values.showCoverPage}
                                label="Show cover page"
                                onChange={e => {
                                    onChange({
                                        ...values,
                                        showCoverPage: e.target.checked,
                                    });
                                }}
                            />
                            {values.showCoverPage ? (
                                <Select
                                    onChange={e => {
                                        const parsedId =
                                            parseEntityIdSafe<EmailTemplateId>(
                                                e.target.value,
                                            );
                                        onChange({
                                            ...values,
                                            coverPageTemplate: parsedId.hasValue
                                                ? parsedId.get()
                                                : null,
                                        });
                                    }}
                                    value={values.coverPageTemplate ?? "_"}>
                                    <option value="_">Select template</option>
                                    {templates.map(t => (
                                        <option key={t.id} value={t.id}>
                                            {t.description ?? "Untitled"}
                                        </option>
                                    ))}
                                </Select>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <em>
                                To include a cover page, create a{" "}
                                <strong>template</strong> in your settings.
                            </em>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};
