import React from "react";
import { IEditor } from "roosterjs";

const grayscaleColors = [
    "rgb(0, 0, 0)",
    "rgb(68, 68, 68)",
    "rgb(102, 102, 102)",
    "rgb(153, 153, 153)",
    "rgb(204, 204, 204)",
    "rgb(238, 238, 238)",
    "rgb(243, 243, 243)",
    "rgb(255, 255, 255)",
];

const mainColors = [
    "rgb(255, 0, 0)",
    "rgb(255, 153, 0)",
    "rgb(255, 255, 0)",
    "rgb(0, 255, 0)",
    "rgb(0, 255, 255)",
    "rgb(0, 0, 255)",
    "rgb(153, 0, 255)",
    "rgb(255, 0, 255)",
];

const shadedColors = [
    [
        "rgb(244, 204, 204)",
        "rgb(252, 229, 205)",
        "rgb(255, 242, 204)",
        "rgb(217, 234, 211)",
        "rgb(208, 224, 227)",
        "rgb(207, 226, 243)",
        "rgb(217, 210, 233)",
        "rgb(234, 209, 220)",
    ],
    [
        "rgb(234, 153, 153)",
        "rgb(249, 203, 156)",
        "rgb(255, 229, 153)",
        "rgb(182, 215, 168)",
        "rgb(162, 196, 201)",
        "rgb(159, 197, 232)",
        "rgb(180, 167, 214)",
        "rgb(213, 166, 189)",
    ],
    [
        "rgb(224, 102, 102)",
        "rgb(246, 178, 107)",
        "rgb(255, 217, 102)",
        "rgb(147, 196, 125)",
        "rgb(118, 165, 175)",
        "rgb(111, 168, 220)",
        "rgb(142, 124, 195)",
        "rgb(194, 123, 160)",
    ],
    [
        "rgb(204, 0, 0)",
        "rgb(230, 145, 56)",
        "rgb(241, 194, 50)",
        "rgb(106, 168, 79)",
        "rgb(69, 129, 142)",
        "rgb(61, 133, 198)",
        "rgb(103, 78, 167)",
        "rgb(166, 77, 121)",
    ],
    [
        "rgb(153, 0, 0)",
        "rgb(180, 95, 6)",
        "rgb(191, 144, 0)",
        "rgb(56, 118, 29)",
        "rgb(19, 79, 92)",
        "rgb(11, 83, 148)",
        "rgb(53, 28, 117)",
        "rgb(116, 27, 71)",
    ],
    [
        "rgb(102, 0, 0)",
        "rgb(120, 63, 4)",
        "rgb(127, 96, 0)",
        "rgb(39, 78, 19)",
        "rgb(12, 52, 61)",
        "rgb(7, 55, 99)",
        "rgb(32, 18, 77)",
        "rgb(76, 17, 48)",
    ],
];

interface ColorPickerPanelProps {
    editor: IEditor;
    current: (editor: IEditor) => string;
    label: string;
    onColorSelected: (color: string) => void;
}

export const ColorPickerPanel: React.FC<ColorPickerPanelProps> = props => {
    const { label, editor, current, onColorSelected } = props;
    const [currentColor, setCurrentColor] = React.useState(current(editor));

    React.useEffect(() => {
        setCurrentColor(current(editor));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleColorSelected = React.useCallback(
        (color: string) => {
            setCurrentColor(color);
            onColorSelected(color);
        },
        [onColorSelected, setCurrentColor],
    );
    return (
        <div className="p-2 space-y-2">
            <p className="text-sm">{label}</p>
            <ul className="space-y-2">
                <li>
                    <ColorPickerRow
                        currentColor={currentColor}
                        colors={grayscaleColors}
                        onColorSelected={handleColorSelected}
                    />
                </li>
                <li>
                    <ColorPickerRow
                        currentColor={currentColor}
                        colors={mainColors}
                        onColorSelected={handleColorSelected}
                    />
                </li>
            </ul>
            <ul className="space-y-0.5">
                {shadedColors.map((colors, i) => (
                    <li key={i}>
                        <ColorPickerRow
                            currentColor={currentColor}
                            colors={colors}
                            onColorSelected={handleColorSelected}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

interface ColorPickerRowProps {
    currentColor: string;
    colors: string[];
    onColorSelected: (color: string) => void;
}

const ColorPickerRow: React.FC<ColorPickerRowProps> = props => {
    const { currentColor, colors, onColorSelected } = props;

    return (
        <ul className="flex space-x-0.5">
            {colors.map(c => {
                const isSelected = currentColor === c;
                return (
                    <li key={c}>
                        <a
                            href="#"
                            className={`relative w-5 h-5 block border border-gray-100 ${
                                isSelected ? "ring-2 ring-offset-0 ring-gray-100" : ""
                            }}`}
                            onClick={e => {
                                e.preventDefault();
                                onColorSelected(c);
                            }}
                            style={{ backgroundColor: c }}></a>
                    </li>
                );
            })}
        </ul>
    );
};
